<template>
  <div id="agencyModal">
    <CCard class="p-0 m-0">
      <CCardBody class="p-0">
        <MyBeeSearchBar @goodPaging="goodPaging" @changePage="changePage" @devSearch="devSearch"
                        :curr-page.sync="pagingParams.currPage"
                        :per-page.sync="pagingParams.perPage"
                        :per-page-list="perPageList"
                        :pages="pages"
                        :search-bar-place-holder="searchBarPlaceHolder"
                        :keyword.sync="pagingParams.keyword">
          <template slot="tools">
            <CButton size="sm" color="warning mr-1 my-1" variant="outline" :pressed="toggleMappingOnly" @click="toggleMappingOnlyButon()">許可のみ</CButton>
          </template>
        </MyBeeSearchBar>
        <BeeGridTable
            ref="bee"
            border
            stripe
            :showFilter="false"
            :columns="columns"
            :loading="isLoading"
            :data="rows"
            :showPager="false"
            :maxHeight="windowHeight / 2"
            highlightRow
            no-data-text="データがありません。"
            no-filtered-data-text=""
            :context="context"
            :span-method="customSpan"
            :showVerticalScrollBar="false"
            :showHorizontalScrollBar="false">
          <!-- HEADER ソート可能にするために 必要-->
          <template slot-scope="{ column,index }" slot="nameHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
          <template slot-scope="{ column,index }" slot="addressHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
          <template slot-scope="{ column,index }" slot="branchCountHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
          <template slot-scope="{ column,index }" slot="createdHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
          <template slot-scope="{ column,index }" slot="updatedHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
          <template slot-scope="{ column,index }" slot="registableHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
          <template slot-scope="{ column,index }" slot="viewableHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>

          <!-- cell -->
          <template slot-scope="{ row,index }" slot="name">
            <div class="text-left">{{ row.name }}</div>
          </template>
          <template slot-scope="{ row,index }" slot="address">
            <div class="text-left">{{ row.address }}</div>
          </template>
          <template slot-scope="{ row,index }" slot="branchCount">
            <div class="text-right">{{ row.branchCount }}</div>
          </template>
          <template slot-scope="{ row,index }" slot="created">
            <div class="text-left">{{ row.created }}</div>
          </template>
          <template slot-scope="{ row,index }" slot="updated">
            <div class="text-left">{{ row.updated }}</div>
          </template>

          <template slot-scope="{ row,index }" slot="registable">
            <div class="float-center">
              <CButton size="sm" color="success mr-1" variant="outline" :pressed="row.regist_patient_flg" @click="mappingHospital(row,index)">{{ row.hospitalId ? '解除する' : '許可する'}}</CButton>
            </div>
          </template>

          <template slot-scope="{ row,index }" slot="viewable">
            <div class="float-center">
              <CButton size="sm" color="success mr-1" variant="outline" :pressed="row.view_vital_flg" @click="mappingHospitalVital(row,index)">{{ row.viewId ? '解除する' : '許可する'}}</CButton>
            </div>
          </template>
        </BeeGridTable>
      </CCardBody>
    </CCard>
  </div>

</template>

<script>

import BeeMix from '@/views/aprod/js/mixin/beeUtils'
import MyBeeHeader from "@/component/parts/MyBeeHeader";
import MyBeeSearchBar from "@/component/parts/MyBeeSearchBar";
export default {
  components: {
    MyBeeHeader, MyBeeSearchBar
  },
  mixins: [BeeMix],
  props: {
    mappingHospitalId: { default: 0, type: Number },
    isShow: { default: false, type: Boolean },
    isModal: { default: false, type: Boolean },
  },data: () => {
        return {
          searchBarPlaceHolder:"販売店名、住所で検索",
          columns: [
            {title: 'id', key: 'id',align:"right"},
            {title: '販売店の名称', key: 'name',slot:"name",headSlot:"nameHead",align:"center"},
            {title: '住所', key: 'address',slot:"address",headSlot:"addressHead",align:"center"},
            {title: '事業所数', key: 'branchCount',slot:"branchCount",headSlot:"branchCountHead",align:"center"},
            {title: '登録日時', key: 'created',slot:"created",headSlot:"createdHead",align:"center"},
            {title: '更新日時', key: 'updated',slot:"updated",headSlot:"updatedHead",align:"center"},
            {title: '登録許可', key: 'regist_patient_flg', slot: 'registable',headSlot: 'registableHead', align:"center"},
            {title: '生体情報参照許可', key: 'view_vital_flg', slot: 'viewable',headSlot: 'viewableHead', align:"center"},
          ],
          toggleMappingOnly:false,
          searchInit:false,
        }
      },
      methods:{
        mappingHospital(row,index){
          this.axios.post('/api/hospital/hospital_agency_mapping', {hospitalId:this.mappingHospitalId,agencyId:row.id},
                  {headers: {'Content-Type': 'application/json'}}).then(response => {
              //登録
              this.rows[index] = Object.assign(this.rows[index],{regist_patient_flg:response.data.result});
          }).catch(error => console.log(error)
          ).finally(() => {
            //this.maxHeight = this.isCollapsed ? closeHeight : openHeight;
            this.isLoading = false;
          });
        },
        mappingHospitalVital(row,index){
          this.axios.post('/api/hospital/hospital_agency_mapping_vital_view', {hospitalId:this.mappingHospitalId,agencyId:row.id},
                  {headers: {'Content-Type': 'application/json'}}).then(response => {
              this.rows[index] = Object.assign(this.rows[index],{view_vital_flg:response.data.result});
          }).catch(error => console.log(error)
          ).finally(() => {
            //this.maxHeight = this.isCollapsed ? closeHeight : openHeight;
            this.isLoading = false;
          });
        },

        toggleMappingOnlyButon(){
          this.toggleMappingOnly = !this.toggleMappingOnly;
          this.goodPaging();
        },
        deviceSelect(row){
          this.$emit("deviceSelect",row);
        },
        goodPaging: function (isPageChange) {
          if (!isPageChange) this.pagingParams.currPage = 1;//ページが以外が変わったら最初から検索する
          this.isLoading = true;
          this.axios.post('/api/agency/agency/registView', {pagingForm:this.pagingParams,mappingHospitalId:this.mappingHospitalId,permitFlg:this.toggleMappingOnly}, {headers: {'Content-Type': 'application/json'}}).then(response => {
            this.totalRecords = response.data.result.totalElements;
            this.rows = response.data.result.content;
            //ページ件数
            this.pages = response.data.result.totalPages;
            if(this.isModal){
              this.topLeft("#agencyModal");
            }else{
              this.topLeft();
            }

          }).catch(error => console.log(error)
          ).finally(() => {
            this.isLoading = false;
          });
        }
      },
      watch:{
        isShow:function (){
          if(this.isShow){
              this.toggleMappingOnly = false;
              this.goodPaging();
          }else{
            this.pagingParams.keyword = null;
          }
        },
      }
    }

</script>
